import styled, { css, keyframes } from 'styled-components';

const dropInAnimation = keyframes`
  from {
    transform: translateY(-0.5rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

/**
 * A Figure style inside an article.
 */
const ArticleFigure = css`
  figure {
    --horizontal-separation: calc(40rem / 16);
    --vertical-separation: 1rem;

    display: inline-grid;
    grid-template-rows: auto auto;
    grid-column-gap: var(--horizontal-separation);
    grid-row-gap: var(--vertical-separation);

    width: calc(100% / 3 + var(--horizontal-separation));

    text-align: justify;

    /**
     * Place every other figure on the opposite side and flip the layout.
     */
    &:nth-of-type(2n + 1) {
      grid-template-columns: 1px 1fr;
      grid-template-areas:
        ' sep picture '
        ' sep caption ';
      float: right;
      margin-left: var(--horizontal-separation);
    }

    &:nth-of-type(2n) {
      grid-template-columns: 1fr 1px;
      grid-template-areas:
        ' picture sep '
        ' caption sep ';
      justify-items: end;
      float: left;
      margin-right: var(--horizontal-separation);
    }

    /**
     * Override style for default gatsby image wrapper.
     */
    .gatsby-resp-image-wrapper {
      margin: 0.5rem 0 0 0 !important;
      width: 100%;
      border-radius: var(--article-figure-corner-radius);
    }

    & > figcaption {
      --font-size: calc(14rem / 16);
      grid-area: caption;
      font-size: var(--font-size);
      line-height: calc(var(--font-size) * 1.5);
      margin-bottom: 0.5rem;
    }

    /**
     * Before and after are used for separation lines, these are flipped to be
     * at the top and bottom for mobile use. Only one is visible in desktop mode,
     * where it separates the figure from the text to its side.
     */
    &::before,
    &::after {
      width: 100%;
      display: block;
      background: currentColor;
      opacity: 0.25;
    }

    &::before {
      content: '';
      grid-area: sep;
    }

    &::after {
      grid-area: sep2;
    }

    @media screen and (max-width: calc(500rem / 16)) {
      &:nth-of-type(1n) {
        width: 100%;

        grid-template-columns: 1fr;
        grid-template-rows: 1px auto auto 1px;
        grid-template-areas:
          ' sep     '
          ' picture '
          ' caption '
          ' sep2    ';

        &::before,
        &::after {
          content: '';
        }

        .gatsby-resp-image-wrapper {
          width: 100%;
          margin: 0.5rem auto 0 auto !important;
        }
      }
    }
  }
`;

const ArticleBody = styled.div`
  --article-font-size: calc(19rem / 16);
  --article-line-height: calc(var(--article-font-size) * 1.85);

  --article-figure-corner-radius: calc(4rem / 16);
  --article-banner-corner-radius: calc(12rem / 16);

  font-size: var(--article-font-size);
  line-height: var(--article-line-height);
  margin-bottom: var(--section-gap);

  animation: ${dropInAnimation} var(--reveal-duration) ease-out 1;
  animation-delay: calc(var(--reveal-stagger) * 3);
  animation-fill-mode: both;

  @media screen and (max-width: calc( 400rem / 16)) {
    --article-font-size: calc(16rem / 16);
  }

  & > * + * {
    margin-top: var(--article-line-height);
  }

  h1, h2, h3, h4, h5 {
    font-weight: 600;
    text-align: left;
  }

  h1 {
    font-size: calc(48rem / 16);

    @media screen and (max-width: calc( 400rem / 16)) {
      --font-size: calc(32rem / 16);
    }
  }

  h2 {
    font-size: calc(40rem / 16);

    @media screen and (max-width: calc( 400rem / 16)) {
      --font-size: calc(24rem / 16);
    }
  }

  h3 {
    font-size: calc(32rem / 16);
    margin-top: var(--section-gap);

    @media screen and (max-width: calc( 400rem / 16)) {
      --font-size: calc(18rem / 16);
    }
  }

  h4 {
    font-size: calc(24rem / 16);
    margin-top: var(--section-gap);

    @media screen and (max-width: calc( 400rem / 16)) {
      --font-size: calc(16rem / 16);
    }
  }

  h5 {
    font-size: calc(21rem / 16);
    margin-top: var(--section-gap);

    @media screen and (max-width: calc( 400rem / 16)) {
      --font-size: calc(14rem / 16);
    }
  }

  p {
    text-align: justify;
    margin-block-start: 0.5em;
    margin-block-end: 1em;
  }

  > p:first-of-type::first-letter {
    --first-letter-size: calc(60rem / 16);

    display: block;
    float: left;
    font-size: var(--first-letter-size);
    line-height: calc(var(--first-letter-size) * 1);
    padding-top: 6px;
    padding-right: 0.5rem;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  .gatsby-resp-image-wrapper {
    overflow: hidden;
  }

  ${ArticleFigure}

  & > p > .gatsby-resp-image-wrapper {
    margin: 0 calc(0rem - var(--article-padding)) !important;
    border-radius: var(--article-banner-corner-radius);
    clear: both;
  }
`;

export default ArticleBody;
