import { useLocation } from '@reach/router';
import { format, parseISO } from 'date-fns';
import { cs } from 'date-fns/locale';
import { graphql, Link } from 'gatsby';
import { Disqus } from 'gatsby-plugin-disqus';
import React from 'react';
import styled, { keyframes } from 'styled-components';

import ArticleBody from '../components/article/article-body';
import Layout from '../components/layout';
import PageShare from '../components/page-share';
import SEO from '../components/seo';
import TimeToRead from '../components/time-to-read';

const dropInAnimation = keyframes`
  from {
    transform: translateY(-0.5rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ArticleContainer = styled.main`
  --article-size: calc(720rem / 16);
  --article-padding: calc(80rem / 16);
  --section-gap: calc(56rem / 16);

  --reveal-duration: 0.4s;
  --reveal-stagger: 0.15s;

  margin: 0 auto;
  padding: 0 var(--article-padding);
  width: 100%;
  max-width: calc(var(--article-size) + (var(--article-padding) * 2));
  text-align: center;

  @media screen and (max-width: calc( 700rem / 16)) {
    --article-padding: calc(40rem / 16);
  }

  @media screen and (max-width: calc( 550rem / 16)) {
    --article-padding: calc(20rem / 16);
  }

  @media screen and (max-width: calc( 400rem / 16)) {
    --article-padding: calc(10rem / 16);
  }
`;

const ArticleHeading = styled.h1`
  --font-size: calc(48rem / 16);
  --line-height: calc(var(--font-size) * 1.15);

  font-family: jaf-lapture-caption, serif;
  font-weight: 600;
  font-style: normal;
  font-size: var(--font-size);
  line-height: var(--line-height);

  margin-bottom: 0.5em;
  padding-top: calc(140rem / 16);

  animation: ${fadeInAnimation} var(--reveal-duration) ease-out 1;

  @media screen and (max-width: calc( 400rem / 16)) {
    --font-size: calc(32rem / 16);
  }
`;

const ArticleHeader = styled.header`
  font-size: 1.25rem;
  margin-bottom: var(--section-gap);

  animation: ${fadeInAnimation} var(--reveal-duration) ease-out 1;
  animation-delay: var(--reveal-stagger);
  animation-fill-mode: both;
`;

const DividerElement = styled.div`
  width: 1em;
  height: 1em;
  background: currentColor;
  transform: rotate(45deg);

  animation: ${fadeInAnimation} var(--reveal-duration) ease-out 1;
  animation-delay: calc(var(--reveal-stagger) * 2);
  animation-fill-mode: both;
`;

const DividerContainer = styled.div`
  --divider-gap: 1rem;

  display: inline-grid;
  grid-gap: var(--divider-gap);
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;

  margin-bottom: var(--section-gap);

  ${DividerElement}:first-child, ${DividerElement}:last-child {
    font-size: calc(13em / 19);
  }
`;

const ArticleDivider = () => (
  <DividerContainer>
    <DividerElement />
    <DividerElement />
    <DividerElement />
  </DividerContainer>
);

const ArticleSiblingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: ' prev next ';
`;

const ArticleSibling = styled(Link)`
  display: block;
  padding: 20px 30px;
  grid-area: ${(p: { dir: 'prev' | 'next' }) => p.dir};
  background-color: var(--background-color);
  border: 1px solid var(--highlight-background-color);
  font-size: calc(24rem / 16);
  font-weight: 600;
  text-align: left;
  text-decoration: none;

  & + & {
    border-left: none;
  }

  &::after {
    display: block;
    content: 'Přečíst příspěvek ⟶';
    text-decoration: underline;
    font-size: calc(14rem / 16);
    font-weight: 400;
  }
`;

export default function Template({ data }) {
  const {
    article,
    siblings: { edges: siblings },
  } = data;
  const { frontmatter, html, timeToRead } = article;

  const location = useLocation();

  const indexOfArticle = siblings.findIndex(
    (a) => a.node.frontmatter.path === frontmatter.path
  );
  const prevArticle = siblings[indexOfArticle - 1]?.node ?? null;
  const nextArticle = siblings[indexOfArticle + 1]?.node ?? null;

  let disqusConfig = {
    url: `${'https://reliktyminulosti.cz' + location.pathname}`,
    identifier: frontmatter.path,
    title: frontmatter.title,
  };

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <ArticleContainer>
        <ArticleHeading>{frontmatter.title}</ArticleHeading>
        <ArticleHeader>
          {frontmatter.date ? (
            <>{format(parseISO(frontmatter.date), 'PP', { locale: cs })}, </>
          ) : null}
          <TimeToRead time={timeToRead}></TimeToRead>
        </ArticleHeader>
        <ArticleDivider />
        <ArticleBody dangerouslySetInnerHTML={{ __html: html }} />
        <ArticleDivider />
        <div style={{ marginBottom: 'var(--section-gap)' }}>
          <PageShare />
        </div>
        <ArticleDivider />
        {prevArticle || nextArticle ? (
          <ArticleSiblingsContainer>
            {prevArticle ? (
              <ArticleSibling dir="prev" to={prevArticle.frontmatter.path}>
                {prevArticle.frontmatter.title}
              </ArticleSibling>
            ) : null}
            {nextArticle ? (
              <ArticleSibling dir="next" to={nextArticle.frontmatter.path}>
                {nextArticle.frontmatter.title}
              </ArticleSibling>
            ) : null}
          </ArticleSiblingsContainer>
        ) : null}
        <div style={{ marginTop: 'var(--section-gap)' }}>
          <Disqus config={disqusConfig} />
        </div>
      </ArticleContainer>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!, $searchPath: String!) {
    article: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      timeToRead
      frontmatter {
        date
        path
        title
      }
    }
    siblings: allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: $searchPath } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fileAbsolutePath
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;
