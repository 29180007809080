import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from '@reach/router';
import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const ShareText = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: var(--page-share-size);
  padding: 0 var(--page-share-padding);

  background: var(--background-color);

  text-transform: uppercase;
  text-align: center;
`;

const Title = styled.div`
  ${ShareText}

  transition: transform var(--transition);
`;

const Links = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 0 calc(var(--page-share-padding) / 2);

  display: flex;
  align-items: stretch;
  justify-items: center;
  justify-content: space-between;

  background: var(--highlight-background-color);
  color: var(--highlight-text-color);

  transform: translateY(100%);
  transition: transform var(--transition);
`;

const Container = styled.div`
  --page-share-size: calc(40rem / 16);
  --page-share-padding: calc(20rem / 16);

  --transition: 0.15s ease-in-out;

  position: relative;
  display: inline-block;
  overflow: hidden;

  border: 1px solid currentColor;

  &:hover,
  &:focus,
  &:focus-within {
    ${Title} {
      transform: translateY(-100%);
    }

    ${Links} {
      transform: translateY(0);
    }
  }
`;

const ShareBtn = css`
  display: block;
  height: 100%;
  width: var(--page-share-size);

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;

  &::before {
    content: '';
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: -1;

    opacity: 0;

    transition: opacity var(--transition);
  }

  &:hover,
  &:active {
    &::before {
      opacity: 1;
    }
  }
`;

const SocialLink = styled.a`
  ${ShareBtn}

  &::before {
    background-color: ${({ brandColor }: { brandColor?: string }) => brandColor ?? 'inherit'};
  }
`;

const CopyLink = styled.button`
  ${ShareBtn}

  border: none;
  background: none;
  color: inherit;
  cursor: pointer;

  &::before {
    background: var(--highlight-background-color);
  }
`;

const copiedReveal = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const CopiedMessage = styled.div`
  ${ShareText}

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  animation: ${copiedReveal} var(--transition) 1;
`;

const PageShare = () => {
  const location = useLocation();
  const siteUrl = 'https://reliktyminulosti.cz' + location.pathname;

  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    siteUrl
  )}`;

  const twitterShareUrl = `http://twitter.com/share?url=${encodeURIComponent(
    siteUrl
  )}`;

  const [copied, setCopied] = useState(false);

  const copyLinkToClipboard = useCallback(
    async (ev: MouseEvent) => {
      ev.preventDefault();

      await navigator.clipboard.writeText(siteUrl);
      setCopied(true);
    },
    [location, setCopied]
  );

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [copied, setCopied]);

  return (
    <Container tabIndex={0}>
      <Title>Sdilet Clanek</Title>
      <Links>
        <li>
          <SocialLink
            href={fbShareUrl}
            target="_blank"
            // brandColor="#3b5998"
          >
            <FontAwesomeIcon icon={faFacebookF} fixedWidth={true} />
          </SocialLink>
        </li>
        <li>
          <SocialLink
            href={twitterShareUrl}
            target="_blank"
            // brandColor="#1da1f2"
          >
            <FontAwesomeIcon icon={faTwitter} fixedWidth={true} />
          </SocialLink>
        </li>
        <li>
          <CopyLink
            type="button"
            onClick={copyLinkToClipboard}
            title="Kopirovat odkaz"
          >
            <FontAwesomeIcon icon={faLink} />
          </CopyLink>
        </li>
      </Links>
      {copied ? <CopiedMessage>Skopirovano</CopiedMessage> : null}
    </Container>
  );
};

export default PageShare;
